import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import "./mobileMenu.css";

const MobileMenu = ({ handleClick }) => {
    const [openMenu, setOpenMenu] = useState(null); // Track the open menu

    const toggleMenu = (menu) => {
        if (openMenu === menu) {
            // If the menu is already open, close it
            setOpenMenu(null);
        } else {
            // Otherwise, open the selected menu and close others
            setOpenMenu(menu);
        }
    };

    return (
        <div className="mobileMenu">
            <ul className="menu">
                <li className="menuItem">
                    <div className="menuLink" onClick={() => toggleMenu("about")}>
                        About
                        {openMenu === "about" ? (
                            <StaticImage
                                className="vectorImg"
                                src="../../assets/images/VectorUp.png"
                                alt="VectorUp"
                            />
                        ) : (
                            <StaticImage
                                className="vectorImg"
                                src="../../assets/images/Vector.png"
                                alt="Vector"
                            />
                        )}
                    </div>
                    {openMenu === "about" && (
                        <div className="submenu">
                            <GatsbyLink to="/theteam" className="submenuLink" onClick={handleClick}>
                                Meet The Team
                            </GatsbyLink>
                            <GatsbyLink to="/aboutaia" className="submenuLink" onClick={handleClick}>
                                About AIA
                            </GatsbyLink>
                            <GatsbyLink to="/whyaia" className="submenuLink" onClick={handleClick}>
                                Why AIA
                            </GatsbyLink>
                            <GatsbyLink to="/FAQ" className="submenuLink" onClick={handleClick}>
                                FAQ
                            </GatsbyLink>
                        </div>
                    )}
                </li>

                <li className="menuItem">
                    <div className="menuLink" onClick={() => toggleMenu("funding")}>
                        Funding
                        {openMenu === "funding" ? (
                            <StaticImage
                                className="vectorImg"
                                src="../../assets/images/VectorUp.png"
                                alt="VectorUp"
                            />
                        ) : (
                            <StaticImage
                                className="vectorImg"
                                src="../../assets/images/Vector.png"
                                alt="Vector"
                            />
                        )}
                    </div>
                    {openMenu === "funding" && (
                        <div className="submenu">
                            <GatsbyLink to="/funding" className="submenuLink" onClick={handleClick}>
                                SSG Funding
                            </GatsbyLink>
                            <GatsbyLink to="/PSEA" className="submenuLink" onClick={handleClick}>
                                PSEA Scheme
                            </GatsbyLink>
                            <GatsbyLink to="/SFC" className="submenuLink" onClick={handleClick}>
                                SkillsFuture Credit
                            </GatsbyLink>
                        </div>
                    )}
                </li>

                <li className="menuItem">
                    <div className="menuLink" onClick={() => toggleMenu("courses")}>
                        All Courses
                        {openMenu === "courses" ? (
                            <StaticImage
                                className="vectorImg"
                                src="../../assets/images/VectorUp.png"
                                alt="VectorUp"
                            />
                        ) : (
                            <StaticImage
                                className="vectorImg"
                                src="../../assets/images/Vector.png"
                                alt="Vector"
                            />
                        )}
                    </div>
                    {openMenu === "courses" && (
                        <div className="submenu">
                            <GatsbyLink to="/courses-ifc" className="submenuLink" onClick={handleClick}>
                                IFC & CORENET X Courses
                            </GatsbyLink>
                            <GatsbyLink to="/courses-autodesk" className="submenuLink" onClick={handleClick}>
                                Autodesk Courses
                            </GatsbyLink>
                            <GatsbyLink to="/courses-bentley" className="submenuLink" onClick={handleClick}>
                                Bentley Courses
                            </GatsbyLink>
                            <GatsbyLink to="/courses-bim" className="submenuLink" onClick={handleClick}>
                                BIM Courses
                            </GatsbyLink>
                            <GatsbyLink to="/courses-skillsfuture" className="submenuLink" onClick={handleClick}>
                                WSQ Courses
                            </GatsbyLink>
                            <GatsbyLink to="/short-courses" className="submenuLink" onClick={handleClick}>
                                1-Day Short Courses
                            </GatsbyLink>
                        </div>
                    )}
                </li>

                <li className="menuItem">
                    <div className="menuLink" onClick={() => toggleMenu("terms")}>
                        Terms & Conditions
                        {openMenu === "terms" ? (
                            <StaticImage
                                className="vectorImg"
                                src="../../assets/images/VectorUp.png"
                                alt="VectorUp"
                            />
                        ) : (
                            <StaticImage
                                className="vectorImg"
                                src="../../assets/images/Vector.png"
                                alt="Vector"
                            />
                        )}
                    </div>
                    {openMenu === "terms" && (
                        <div className="submenu">
                            <GatsbyLink to="/reschedule" className="submenuLink" onClick={handleClick}>
                                Re-scheduling Policy
                            </GatsbyLink>
                            <GatsbyLink to="/reschedule" className="submenuLink" onClick={handleClick}>
                                Withdrawal/Refund Policy
                            </GatsbyLink>
                            <GatsbyLink to="/reschedule" className="submenuLink" onClick={handleClick}>
                                Appeal Policy and Procedure
                            </GatsbyLink>
                            <GatsbyLink to="/reschedule" className="submenuLink" onClick={handleClick}>
                                Feedback/Complaint
                            </GatsbyLink>
                            <GatsbyLink to="/reschedule" className="submenuLink" onClick={handleClick}>
                                Personal Data Protection Act
                            </GatsbyLink>
                        </div>
                    )}
                </li>

                <li className="menuItem">
                    <div className="menuLink" onClick={() => toggleMenu("learners")}>
                        For Learners
                        {openMenu === "learners" ? (
                            <StaticImage
                                className="vectorImg"
                                src="../../assets/images/VectorUp.png"
                                alt="VectorUp"
                            />
                        ) : (
                            <StaticImage
                                className="vectorImg"
                                src="../../assets/images/Vector.png"
                                alt="Vector"
                            />
                        )}
                    </div>
                    {openMenu === "learners" && (
                        <div className="submenu">
                            <GatsbyLink to="/WithdrawalForm" className="submenuLink" onClick={handleClick}>
                                Request for Withdrawal
                            </GatsbyLink>
                            <GatsbyLink to="/ScheduleForm" className="submenuLink" onClick={handleClick}>
                                Request for Schedule
                            </GatsbyLink>
                            <GatsbyLink to="/AppealForm" className="submenuLink" onClick={handleClick}>
                                Request for Appeal
                            </GatsbyLink>
                        </div>
                    )}
                </li>

                <li className="menuItem">
                    <GatsbyLink to="/contact" className="menuLink" onClick={handleClick}>
                        Contact
                    </GatsbyLink>
                </li>
            </ul>
        </div>
    );
};

export default MobileMenu;
