import React, { useEffect, useState } from "react";
import SEO from "../components/seo";

const ScheduleForm = () => {
    const [iframeHeight, setIframeHeight] = useState("800px"); // Default height

    useEffect(() => {
        const handleMessage = (event) => {
            // Ensure the event is from the correct origin
            if (event.origin === "https://forms.office.com") {
                // Update the iframe height
                setIframeHeight(`${event.data}px`);
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <>
            <SEO
                title="BIM & CAD Training Center | Ace Industrial Academy"
                description="Ace Industrial Academy is a leading training center in Singapore for IT courses, BIM courses, 3D CAD courses, 4D courses, software courses, building information modeling courses and more."
            />
            <div className="iframe-container" style={{ textAlign: "center", marginTop: "20px" }}>
                <iframe
                    title="Office Form"
                    src="https://forms.office.com/Pages/ResponsePage.aspx?id=oI4KNswNAUmt8-8y741LdBCeyzF9YZdIlYXHTEZt6Z5URFZRQUZBNjVUTU5XR08zOUxEU1NQSTAwQS4u&embed=true"
                    frameBorder="0"
                    marginWidth="0"
                    marginHeight="0"
                    style={{
                        width: "100%",
                        height: iframeHeight,
                        border: "none",
                        maxWidth: "100%",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    allowFullScreen
                    webkitAllowFullScreen
                    mozAllowFullScreen
                    msAllowFullScreen
                ></iframe>
            </div>
        </>
    );
};

export default ScheduleForm;

