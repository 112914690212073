import React from "react"
import "./header.scss"
import Logo from "../../assets/aia-logo"
import { Link as GatsbyLink } from "gatsby"
import Menu from "./Menu"
import MobileMenu from "./MobileMenu" // Assuming MobileMenu is the mobile version of Menu
import { StaticImage } from "gatsby-plugin-image"
import { useMediaQuery } from "react-responsive"

const Header = (props) => {
  const { isMenuOpen, handleClick } = props

  const isTablet = useMediaQuery({
    query: "(max-width: 767px)",
  })

  return (
    <header className="header">
      <div className="container">
        <div className="headerInnerWrapper">
          <div className="headerLeft">
            <h1>
              <GatsbyLink to="/">
                <Logo />
              </GatsbyLink>
            </h1>
          </div>
          <div className="headerRight">
            {isTablet ? (
              <div
                className="hamburgerIconCover"
                onClick={handleClick}
              >
                {isMenuOpen ? (
                  <StaticImage
                    src="../../assets/images/cancelIcon.png"
                    alt="Cancel Menu"
                  />
                ) : (
                  <StaticImage
                    src="../../assets/images/hamburgerIcon.png"
                    alt="Open Menu"
                  />
                )}
              </div>
            ) : (
              <Menu />
            )}
          </div>
        </div>
        {isMenuOpen && isTablet && (
          <div className="mobileHeaderViewCover">
            <MobileMenu handleClick={handleClick} />
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
