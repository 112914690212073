import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../../context/GlobalContextProvider"
import { navigate } from "gatsby"
import { ReactSearchAutocomplete } from "react-search-autocomplete"
import "./search.scss"

const SearchBox = () => {
  const state = useContext(GlobalStateContext)
  const [searchQuery, setSearchQuery] = useState("")
  const [coursesList, setCoursesList] = useState([])

  const dispatch = useContext(GlobalDispatchContext)

  const fetchAllCourses = useCallback(() => {
    const allCourses = []

    if (state) {
      state.courses.map((course, index) => {
        const overview =
          course.overview?.length > 140
            ? course.overview.substring(0, 140) + "..."
            : course.overview
        const searchResultObj = {
          key: course.slug,
          id: index,
          title: course.title,
          overview: overview,
          tag: Array.isArray(course.category) ? course.category.join() : "",
          value: [
            course.title,
            <span>{overview}</span>,
            <div className="tags">
              {course.category &&
                course.category.map((tag, i) => (
                  <span key={i} className="autodesk_btn">
                    {tag}
                  </span>
                ))}
            </div>,
          ],
        }
        allCourses.push(searchResultObj)
      })
    }

    setCoursesList(allCourses)
  }, [state])

  useEffect(() => {
    state && fetchAllCourses()
    return () => {
      setCoursesList([])
    }
  }, [state, fetchAllCourses])

  const handleSearchAction = () => {
    if (searchQuery && searchQuery.trim().length > 0) {
      console.log("Search query:", searchQuery);
      const trimmedQuery = searchQuery.trim();
      const formattedQuery = trimmedQuery.replace(/\s+/g, "-").toLowerCase();
      console.log("Formatted query:", formattedQuery);

      dispatch({
        type: "SET_COURSE_SEARCH_QUERY",
        payload: trimmedQuery, // Dispatch the trimmed query
      });

      const searchResults = state?.courses.filter(
        (course) =>
          course.title.toLowerCase().includes(trimmedQuery.toLowerCase()) ||
          (Array.isArray(course.category) &&
            course.category.some((cat) =>
              cat.toLowerCase().includes(trimmedQuery.toLowerCase())
            ))
      );

      dispatch({
        type: "SET_COURSE_SEARCH_RESULTS",
        payload: searchResults, // Dispatch filtered results
      });

      navigate(`/courses?searchquery=${formattedQuery}`); // Navigate to the query-based page
    }
  };

  const formatResult = (item) => {
    return (
      <div className="react-search-box-dropdown-list-item">
        <div>{item.title}</div>
        <span>{item.overview}</span>
        {item.value[2]}
      </div>
    )
  }

  return (
    <div
      className="customSearchboxContainer"
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent default behavior
          console.log("Enter pressed, triggering search...");
          handleSearchAction(); // Run search action
        }
      }}
    >
      <div className="searchIconCover">
        <StaticImage
          src="../../../assets/images/search.svg"
          alt=""
        />
      </div>
      <ReactSearchAutocomplete
        inputSearchString={searchQuery}
        items={coursesList}
        onSearch={(query) => {
          setSearchQuery(query); // Update search query state
        }}
        onSelect={(obj) => {
          if (obj && obj.key) {
            navigate(`/courses/${obj.key}`); // Navigate to the specific course
          }
        }}
        placeholder="Search for Courses"
        fuseOptions={{ keys: ["title"] }}
        resultStringKeyName="title"
        formatResult={formatResult}
        showIcon={false}
        maxResults={5}
        showNoResults={false}
        styling={{
          border: "none",
          boxShadow: "none",
          searchIconMargin: -1,
          borderRadius: "0",
        }}
      />
      <button
        className="searchbtn"
        onClick={handleSearchAction} // Trigger search on button click
      >
        Search
      </button>
    </div>
  )
}

export default SearchBox