import React, { useState } from "react"
import { Link as GatsbyLink, navigate } from "gatsby"
import { graphql, useStaticQuery } from 'gatsby';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown, } from "reactstrap"
import { StaticImage } from "gatsby-plugin-image"
import "./menu.css"

const Menu = ({ handleClick }) => {
  const [isShowItem, setShowItem] = useState(false)
  const toggleAboutMenu = () => {
    setShowItem(!isShowItem)
  }
  const [isOpenItem, setOpenItem] = useState(false)
  const toggleCourseMenu = () => {
    setOpenItem(!isOpenItem)
  }
  const [isOpennItem, setOpennItem] = useState(false)
  const toggleFundingMenu = () => {
    setOpennItem(!isOpennItem)
  }
  const [isOpennnItem, setOpennnItem] = useState(false)
  const toggleTermsMenu = () => {
    setOpennnItem(!isOpennnItem)
  }
  const [isOpennnnItem, setOpennnnItem] = useState(false)
  const toggleLearnersMenu = () => {
    setOpennnnItem(!isOpennnnItem)
  }

  const handleScroll = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    const offset = 154;
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top, behavior: "smooth" });
    }
  };

  const data = useStaticQuery(graphql`
    query {
      ifcCourses: allContentfulTraining(filter: { category: { eq: "ifc" } }) {
        nodes {
          id
          title
          slug
        }
      }
      autodeskCourses: allContentfulTraining(filter: { category: { eq: "autodesk" } }) {
        nodes {
          id
          title
          slug
        }
      }
      bentleyCourses: allContentfulTraining(filter: { category: { eq: "bentley" } }) {
        nodes {
          id
          title
          slug
        }
      }
      bimCourses: allContentfulTraining(filter: { category: { eq: "bim" } }) {
        nodes {
          id
          title
          slug
        }
      }
      skillsFutureCourses: allContentfulTraining(filter: { category: { eq: "SkillsFuture" } }) {
        nodes {
          id
          title
          slug
        }
      }
      shortCourses: allContentfulTraining(filter: { category: { eq: "short-courses" } }) {
        nodes {
          id
          title
          slug
        }
      }
    }
  `);

  const ifcCourses = data.ifcCourses.nodes;
  const autodeskCourses = data.autodeskCourses.nodes;
  const bentleyCourses = data.bentleyCourses.nodes;
  const bimCourses = data.bimCourses.nodes;
  const skillsFutureCourses = data.skillsFutureCourses.nodes;
  const shortCourses = data.shortCourses.nodes;

  return (
    <ul className="menu">
      {/* <li className="menuItem">
        <GatsbyLink
          to="/"
          className="menuLink"
          activeClassName="active"
          onClick={handleClick}
        >
          Home
        </GatsbyLink>
      </li> */}
      <li className="menuItem">
        <Dropdown isOpen={isShowItem} toggle={toggleAboutMenu}>
          <DropdownToggle
            className="menuLink"
            nav
            aria-hidden="true"
          >
            About{" "}
            {isShowItem === true ? (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/VectorUp.png"
                alt="VectorUp"
              />
            ) : (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/Vector.png"
                alt="Vector"
              />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <GatsbyLink
                to="/theteam"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                Meet The Team
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/aboutaia"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                About AIA
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/whyaia"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                Why AIA
              </GatsbyLink>
            </DropdownItem><DropdownItem>
              <GatsbyLink
                to="/FAQ"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                FAQ
              </GatsbyLink>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </li>
      <li className="menuItem">
        <Dropdown isOpen={isOpennItem} toggle={toggleFundingMenu}>
          <DropdownToggle
            className="menuLink"
            nav
            aria-hidden="true"
          >
            Funding{" "}
            {isOpennItem === true ? (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/VectorUp.png"
                alt="VectorUp"
              />
            ) : (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/Vector.png"
                alt="Vector"
              />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <GatsbyLink
                to="/funding"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                SSG Funding
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/PSEA"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                PSEA Scheme
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/SFC"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                SkillsFuture Credit
              </GatsbyLink>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </li>

      <li className="menuItem">
        <Dropdown isOpen={isOpenItem} toggle={toggleCourseMenu}>
          <DropdownToggle className="menuLink" nav>
            All Courses
            {isOpenItem ? (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/VectorUp.png"
                alt="VectorUp"
              />
            ) : (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/Vector.png"
                alt="Vector"
              />
            )}
          </DropdownToggle>
          {isOpenItem && (
            <DropdownMenu className="wideDropdownMenu">
              <div className="dropdownItemsContainer">
                <DropdownItem className="dropdownItem">
                  <GatsbyLink
                    to="/courses-ifc"
                    className="menuLink1"
                    activeClassName="active"
                    onClick={handleClick}
                  >
                    IFC & CORENET X Courses
                  </GatsbyLink>
                  <ul className="dropdownList">
                    {ifcCourses.map(course => (
                      <DropdownItem className="dropdownSubItem" key={course.id}>
                        <GatsbyLink
                          to={`/courses/${course.slug}`}
                          className="submenuLink"
                          onClick={handleClick}
                        >
                          {course.title}
                        </GatsbyLink>
                      </DropdownItem>
                    ))}
                  </ul>
                </DropdownItem>
                <DropdownItem className="dropdownItem">
                  <GatsbyLink
                    to="/courses-autodesk"
                    className="menuLink1"
                    activeClassName="active"
                    onClick={handleClick}
                  >
                    Autodesk Courses
                  </GatsbyLink>
                  <ul className="dropdownList">
                    {autodeskCourses.map(course => (
                      <DropdownItem className="dropdownSubItem" key={course.id}>
                        <GatsbyLink
                          to={`/courses/${course.slug}`}
                          className="submenuLink"
                          onClick={handleClick}
                        >
                          {course.title}
                        </GatsbyLink>
                      </DropdownItem>
                    ))}
                  </ul>
                </DropdownItem>
                <DropdownItem className="dropdownItem">
                  <GatsbyLink
                    to="/courses-bentley"
                    className="menuLink1"
                    activeClassName="active"
                    onClick={handleClick}
                  >
                    Bentley Courses
                  </GatsbyLink>
                  <ul className="dropdownList">
                    {bentleyCourses.map(course => (
                      <DropdownItem className="dropdownSubItem" key={course.id}>
                        <GatsbyLink
                          to={`/courses/${course.slug}`}
                          className="submenuLink"
                          onClick={handleClick}
                        >
                          {course.title}
                        </GatsbyLink>
                      </DropdownItem>
                    ))}
                  </ul>
                </DropdownItem>
                <DropdownItem className="dropdownItem">
                  <GatsbyLink
                    to="/courses-bim"
                    className="menuLink1"
                    activeClassName="active"
                    onClick={handleClick}
                  >
                    BIM Courses
                  </GatsbyLink>
                  <ul className="dropdownList">
                    {bimCourses.map(course => (
                      <DropdownItem className="dropdownSubItem" key={course.id}>
                        <GatsbyLink
                          to={`/courses/${course.slug}`}
                          className="submenuLink"
                          onClick={handleClick}
                        >
                          {course.title}
                        </GatsbyLink>
                      </DropdownItem>
                    ))}
                  </ul>
                </DropdownItem>
                <DropdownItem className="dropdownItem">
                  <GatsbyLink
                    to="/courses-skillsfuture"
                    className="menuLink1"
                    activeClassName="active"
                    onClick={handleClick}
                  >
                    WSQ Courses
                  </GatsbyLink>
                  <ul className="dropdownList">
                    {skillsFutureCourses.map(course => (
                      <DropdownItem className="dropdownSubItem" key={course.id}>
                        <GatsbyLink
                          to={`/courses/${course.slug}`}
                          className="submenuLink"
                          onClick={handleClick}
                        >
                          {course.title}
                        </GatsbyLink>
                      </DropdownItem>
                    ))}
                  </ul>
                </DropdownItem>
                <DropdownItem className="dropdownItem">
                  <GatsbyLink
                    to="/short-courses"
                    className="menuLink1"
                    activeClassName="active"
                    onClick={handleClick}
                  >
                    1-Day Short Courses
                  </GatsbyLink>
                  <ul className="dropdownList">
                    {shortCourses.map(course => (
                      <DropdownItem className="dropdownSubItem" key={course.id}>
                        <GatsbyLink
                          to={`/courses/${course.slug}`}
                          className="submenuLink"
                          onClick={handleClick}
                        >
                          {course.title}
                        </GatsbyLink>
                      </DropdownItem>
                    ))}
                  </ul>
                </DropdownItem>
              </div>
            </DropdownMenu>
          )}
        </Dropdown>
      </li>

      <li className="menuItem">
        <Dropdown isOpen={isOpennnItem} toggle={toggleTermsMenu}>
          <DropdownToggle
            className="menuLink"
            nav
            aria-hidden="true"
            onClick={(e) => {
              if (window.location.pathname !== "/reschedule") {
                navigate("/reschedule");
              }
            }}
          >
            Terms & Conditions{" "}
            {isOpennnItem ? (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/VectorUp.png"
                alt="VectorUp"
              />
            ) : (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/Vector.png"
                alt="Vector"
              />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <GatsbyLink
                to="/#reschedule-policy"
                className="menuLink"
                activeClassName="active"
                onClick={(e) => handleScroll(e, "reschedule-policy")}
              >
                Re-scheduling Policy
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="#withdrawal-policy"
                className="menuLink"
                activeClassName="active"
                onClick={(e) => handleScroll(e, "withdrawal-policy")}
              >
                Withdrawal/Refund Policy
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="#appeal-policy"
                className="menuLink"
                activeClassName="active"
                onClick={(e) => handleScroll(e, "appeal-policy")}
              >
                Appeal Policy and Procedure
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="#feedback"
                className="menuLink"
                activeClassName="active"
                onClick={(e) => handleScroll(e, "feedback")}
              >
                Feedback/Complaint
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="#personal-data"
                className="menuLink"
                activeClassName="active"
                onClick={(e) => handleScroll(e, "personal-data")}
              >
                Personal Data Protection Act
              </GatsbyLink>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </li>
      <li className="menuItem">
        <Dropdown isOpen={isOpennnnItem} toggle={toggleLearnersMenu}>
          <DropdownToggle
            className="menuLink"
            nav
            aria-hidden="true"
            onClick={(e) => {
              if (window.location.pathname !== "/forlearners") {
                navigate("/forlearners");
              }
            }}
          >
            For Learners{" "}
            {isOpennnnItem ? (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/VectorUp.png"
                alt="VectorUp"
              />
            ) : (
              <StaticImage
                className="vectorImg"
                src="../../assets/images/Vector.png"
                alt="Vector"
              />
            )}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <GatsbyLink
                to="/WithdrawalForm"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                Request for Withdrawal
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/ScheduleForm"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                Request for Schedule
              </GatsbyLink>
            </DropdownItem>
            <DropdownItem>
              <GatsbyLink
                to="/AppealForm"
                className="menuLink"
                activeClassName="active"
                onClick={handleClick}
              >
                Request for Appeal
              </GatsbyLink>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </li>
      <li className="menuItem">
        <GatsbyLink
          to="/contact"
          className="menuLink"
          activeClassName="active"
          onClick={handleClick}
        >
          Contact
        </GatsbyLink>

        {/* <script src="https://www.myvelox.com/widget/velox_widget.js"
          type="text/javascript"
          id="velox_widget"
          velox-access-id="XEm6Iw4H5u2lPdsdJZSu"
          velox-key="SeTEd911DMoQ94F5V1IZ23WSuIb7R9GEIpeaAT4I"
          velox-logo="https://www1.bca.gov.sg/images/default-source/corenet-x-ifc_sg/ais.png?sfvrsn=81040b0e_2"
          velox-description="ACE Group"
          _widget-mobile-position="right"
        ></script> */}
      </li>
    </ul>
  )
}

export default Menu
